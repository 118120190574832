import { Error } from '@twlvxtwlv/ui/src/components/shared/error'
import { Page } from '@twlvxtwlv/ui/src/components/shared/page'
import type { GetStaticPropsContext } from 'next'
import { useSession } from 'next-auth/react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export default function CustomErrorPage() {
  const { status: authenticationStatus } = useSession()

  return (
    <Page bgColor="txtDarkGray" authenticationStatus={authenticationStatus}>
      <Error statusCode={404} />
    </Page>
  )
}

export const getStaticProps = async ({ locale = 'de' }: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'navigation', 'footer'])),
    },
  }
}
